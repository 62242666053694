.formula {
    font-size: 18px;
    margin-bottom: 10px;
    background-color: #f0f0f0; /* Change to your desired background color */
    padding: 10px; /* Add padding for better visual appearance */
  }
  .first-section{
    margin-top: 6rem;
    background-color: red;
    height: 500px;
  }
    .table-row{
      margin-top: 20px;
    }
    .bred{
      margin-top: 30px;
    }
  
  
  .main-section{
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      margin-top: 2vh;
      background-color:#E9ECEF;
  } 
  
  #date {
      width: 30vw;
      margin-left: 15rem;
      cursor: pointer;
  
  }
  .tool-heading{
    border-radius: 5px;
    background-color:#1B212F;
  }
  .tool-content{
    margin-top: 1rem;
  }
  .head{
    margin-top: 1rem;
  }
  #input {
      width: 30vw;
      margin-left: 15rem;
  }
  #income {
      width: 30vw;
      margin-left: 15rem;
  }
  #button {
      width: 30vw;
      margin-left: 15rem;
  
  }
  #expenses {
      width: 30vw;
      margin-left: 15rem;
  
  }
  .input-row {
      margin-top: 2rem;
  }
  
  
  .ads{
    margin-top: 2.2rem;
    height: 250px;
    background-color: #F5F5F5;
  
  } 
  .tools-list {
    height: 300px; /* Fixed height for the container */
    background-color: #f5f5f5;
    border-radius: 8px; /* Rounded corners for the container */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    padding: 1rem; /* Add padding to the container */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  }
  
  .tools-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0; /* Remove default margin */
  }
  
  .tools-list li {
    margin-bottom: 10px;
  }
  
  .tools-list li a {
    text-decoration: none;
    color: #333;
    font-size: 18px;
    display: inline-block;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #f0f0f0;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  .text{
    margin-top: 3rem;
    border-radius: 4px;
    background-color:#721DB2;
    height: 50px;
  }
  
  .tools-list li a:hover {
    background-color: #007bff;
    color: #fff;
  }
  