body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  color: #333;
}

.header {
  background: linear-gradient(90deg, #6a0dad, #b19cd9); /* Deep purple to light purple gradient */
  color: white;
  padding: 20px 0;
}

.header .navbar-brand {
  font-size: 24px;
  font-weight: bold;
}

.header .navbar-nav .nav-link {
  color: white;
  font-weight: bold;
}

.header .navbar-nav .nav-link:hover {
  color: #e0e0e0; /* Slightly lighter color on hover */
}

.main-section {
  padding: 50px 20px;
  background-color: white;
  text-align: center;
}

.main-section h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #6a0dad; /* Deep purple */
}

.main-section p.lead {
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.6;
  color: #333;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #6a0dad;
  color: white;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
}

.cta-button:hover {
  background-color: #b19cd9; /* Light purple on hover */
}

.footer {
  background: linear-gradient(90deg, #6a0dad, #b19cd9); /* Deep purple to light purple gradient */
  color: white;
  padding: 20px 0;
}

.footer p {
  margin: 10px 0;
}

.footer nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer nav ul li {
  margin-left: 20px;
}

.footer nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.footer nav ul li a:hover {
  color: #e0e0e0; /* Slightly lighter color on hover */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

