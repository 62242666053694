/* AboutUs.css */
.card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-img-top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  h1 {
    color: #007bff;
  }
  
  .lead {
    color: #333;
  }
  
  .card-title {
    color: #333;
    font-weight: bold;
  }
  
  .card-text {
    color: #555;
  }

/* Hero Section */
.hero {
  background-color: #f4f4f4;
  padding: 50px 0;
  text-align: center;
}

.hero h2 {
  font-size: 2.5rem;
}

.cta-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

/* Calculator Section */
.calculators {
  padding: 40px;
  background-color: #fff;
}

.calculator-grid {
  display: flex;
  justify-content: space-around;
}

.image-aboutus-banner {
  background: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  padding-top: 110px;
  padding-bottom:110px;
}

.calculator-card {
  background-color: #e0e0e0;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  width: 30%;
}

.cta-link {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}
  
  